const ACCEPT_GA = 'cookiesAceiteGA';

document.addEventListener("turbolinks:load", function () {
  if (!localStorage.getItem(ACCEPT_GA)) {
    var modal = document.querySelector('#cookies-modal');
    modal.style.display = 'block';
    var btnClicado = document.querySelectorAll('[name=btn-accept-terms]');
    btnClicado.forEach(function(btn){
      btn.addEventListener('click', function(event){
        localStorage.setItem(ACCEPT_GA, event.target.value);
        modal.style.display = 'none';
      });
    });
  }
});



